import { Children, useMemo } from 'react'
import * as Tabs from '@radix-ui/react-tabs'
import './tabs.css'

export const TabsComponent = ({ children, defaultValue, value, onChange, classNames }) => {
  const defaultTab = useMemo(
    () =>
      defaultValue ? defaultValue : Children.toArray(children)[0]?.props?.value,
    [defaultValue, children]
  )

  return (
    <>
      {Children.count(children) > 0 && (
        <Tabs.Root
          defaultValue={defaultTab}
          value={value}
          onValueChange={onChange}
          className={classNames}
        >
          <Tabs.List className='flex justify-start mb-6 mt-4 w-full border-b'>
            {Children.map(children, ({ props }) => (
              <Tabs.Trigger
                value={props.value}
                className='mr-12 pb-3 transition-colors ease-in-out text-lg duration-300 text-grey-400 TabsTrigger'
                disabled={props.disabled}
              >
                {props.label}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          {children}
        </Tabs.Root>
      )}
    </>
  )
}

export const Tab = ({ children, ...props }) => {
  return <Tabs.Content {...props}>{children}</Tabs.Content>
}
