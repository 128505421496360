import { useUserInputsContext } from 'providers/UserInputsProvider'
import MainHeader from '../Header'
import AdvancedSettings from './AdvancedSettings'
import { BRAZIL_DEFAULT, BASELINE_RUN, ADVANCED_SETTINGS } from 'constants'
import RadioComponent from './RadioComponent'
import { ControlledSelect } from 'components/Inputs/Select'
import { CalendarIcon } from '@heroicons/react/24/outline'
import ControlledRadioGroup from 'components/Inputs/RadioGroup/ControlledRadioGroup'
import { months } from 'utils/date'
import dayjs from 'dayjs'
import { useEffect, useMemo } from 'react'
import { useLocalization } from 'providers/LocalizationProvider'
import { ARGENTINA_DEFAULT } from 'constants'
import OptimizationRunSettings from './OptimizationRunSettings'
import BaselineRunSettings from './BaselineRunSettings'

const Settings = () => {
  const { t } = useLocalization()
  const { control, trigger, errors, watch, setValue } = useUserInputsContext()
  const { countryCode } = useLocalization()
  const type = watch('type')

  const optimizationRun = useMemo(() => {
    switch (countryCode) {
      case 'ARG':
        return ARGENTINA_DEFAULT
      default:
        return BRAZIL_DEFAULT
    }
  }, [countryCode])

  useEffect(() => {
    const currentMonth = dayjs().month() + 1
    const currentYear = dayjs().year()
    const endMonth = 3
    const endYear = dayjs().year() + 1
    setValue('startMonth', currentMonth)
    setValue('startYear', currentYear)
    setValue('endMonth', endMonth)
    setValue('endYear', endYear)
  }, [setValue])

  const firstAvailableDate = dayjs().startOf('year')

  const monthOptions = months.map((month, idx) => ({
    label: month,
    value: idx + 1
  }))

  const yearOptions = Array(6)
    .fill()
    .map((_, idx) => ({
      value: firstAvailableDate.year() - 1 + idx,
      label: firstAvailableDate.year() - 1 + idx,
    }));

  const typeOptions = [
    {
      value: optimizationRun,
      label: t(
        'newOptimizationScreen.sections.settings.runTypes.optimized.label'
      ),
      description: t(
        'newOptimizationScreen.sections.settings.runTypes.optimized.description'
      )
    },
    {
      value: BASELINE_RUN,
      label: t(
        'newOptimizationScreen.sections.settings.runTypes.baseline.label'
      ),
      description: t(
        'newOptimizationScreen.sections.settings.runTypes.baseline.description'
      )
    },
    {
      value: ADVANCED_SETTINGS,
      label: t(
        'newOptimizationScreen.sections.settings.runTypes.advanced.label'
      ),
      description: t(
        'newOptimizationScreen.sections.settings.runTypes.advanced.description'
      )
    }
  ]

  const startMonthValidator = (value, form) => {
    const currentYear = dayjs().year();
    const isCurrentYear = form.startYear === currentYear;
    const isPreviousYear = form.startYear === currentYear - 1;

    if (isCurrentYear && !(value > firstAvailableDate.month())) {
      return t('newOptimizationScreen.sections.settings.validation.startMonth');
    }

    if (isPreviousYear && !(value >= 1)) {
      return t('newOptimizationScreen.sections.settings.validation.startMonth');
    }
  };

  const endMonthValidator = (value, form) => {
    const isStartEndYearEqual = form.startYear === form.endYear;
    if (isStartEndYearEqual && value < form.startMonth) {
      return t('newOptimizationScreen.sections.settings.validation.endDate');
    }

    const isPreviousYear = form.endYear === dayjs().year() - 1;
    if (isPreviousYear && !(value >= 1)) {
      return t('newOptimizationScreen.sections.settings.validation.endDate');
    }
  };

  return (
    <div className='mr-auto pr-4 2xl:mr-20'>
      <div className='ml-6'>
        <MainHeader />

        <h3 className='font-bold mb-3 text-base'>Select model period</h3>

        <div className='flex flex-row gap-14 mb-2'>
          <div>
            <p className='mb-1'>From: </p>
            <div className='flex flex-row items-start gap-8'>
              <ControlledSelect
                options={monthOptions}
                controllerProps={{
                  name: 'startMonth',
                  control,
                  rules: {
                    validate: startMonthValidator
                  }
                }}
                icon={<CalendarIcon />}
                showErrors={false}
                onChange={() => trigger()}
              />
              <ControlledSelect
                options={yearOptions}
                controllerProps={{
                  name: 'startYear',
                  control
                }}
                onChange={() => trigger()}
                showErrors={false}
              />
            </div>
          </div>

          <div>
            <p className='mb-1'>To: </p>
            <div className='flex flex-row items-start gap-8'>
              <ControlledSelect
                options={monthOptions}
                controllerProps={{
                  name: 'endMonth',
                  control,
                  rules: {
                    validate: endMonthValidator
                  }
                }}
                onChange={() => trigger()}
                icon={<CalendarIcon />}
                showErrors={false}
              />
              <ControlledSelect
                options={yearOptions}
                controllerProps={{
                  name: 'endYear',
                  control,
                  rules: {
                    validate: (value, form) => {
                      if (value < form.startYear) {
                        return t(
                          'newOptimizationScreen.sections.settings.validation.endDate'
                        )
                      }
                    }
                  }
                }}
                onChange={() => trigger()}
                showErrors={false}
              />
            </div>
          </div>
        </div>
        <div className='mb-11'>
          {Array.from(
            new Set(Object.values(errors)?.map(({ message }) => message))
          ).map((message, index) => {
            return (
              <p key={`error-message-${index}`} className='text-error-400 mb-2'>
                {message}
              </p>
            )
          })}
        </div>

        <h3 className='font-bold mb-3 text-base'>
          {t('newOptimizationScreen.sections.settings.selectSettings')}
        </h3>
        <ControlledRadioGroup
          optionClassName='w-full'
          RadioBody={RadioComponent}
          controllerProps={{
            name: 'type',
            control
          }}
          options={typeOptions}
        />
        <div className='mt-12'>
          {type === optimizationRun ? (
            <OptimizationRunSettings />
          ) : type === BASELINE_RUN ? (
            <BaselineRunSettings />
          ) : (
            <AdvancedSettings type={ADVANCED_SETTINGS} />
          )}
        </div>
      </div>
    </div>
  )
}
export default Settings
