import { useState, memo, useMemo, useCallback } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import * as Accordion from '@radix-ui/react-accordion';
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart
} from 'recharts';

import { useLocalization } from 'providers/LocalizationProvider'
import Loader from 'components/Feedback/Loader/Loader';

// Utility functions:

/**
 * Abbreviates long labels for better visualization
 */
const abbreviateLabel = (label) => {
  if (!label) return '';
  return label.length > 20 ? `${label.substring(0, 17)}...` : label;
};

/**
 * Normalizes a date string to ISO format
 */
function normalizeDate(dateStr) {
  const date = new Date(dateStr);
  if (isNaN(date)) return null;
  return date.toISOString();
}

/**
 * Normalizes chart data by combining inboundConsolidated and originationCifAssets
 */
const normalizeChartData = (inboundConsolidated, originationCifAssets) => {
  const dataMap = new Map();

  inboundConsolidated.forEach((item) => {
    const key = `${item.asset}-${item.month}`;
    dataMap.set(key, { ...item });
  });

  originationCifAssets.forEach((item) => {
    const key = `${item.asset}-${item.month}`;
    if (dataMap.has(key)) {
      dataMap.set(key, { ...dataMap.get(key), ...item });
    } else {
      dataMap.set(key, { ...item });
    }
  });

  return Array.from(dataMap.values());
};

/**
 * Formats a date string to month-year format
 */
function formatMonth(dateValue){
  if (!dateValue) return '';

  // Force UTC timezone to avoid date shifting
  const date = new Date(`${dateValue}T00:00:00Z`);
  if (isNaN(date)) return '';

  const monthShort = date.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
  const year = date.getUTCFullYear();

  return `${monthShort}-${year}`;
}

// Custom components:

/**
 * Custom tick component for chart X-axis
 */
function CustomTick({ x, y, payload }) {
  const { value } = payload;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={10}
        textAnchor="end"
        fontSize={12}
        fill="#000"
        transform="rotate(-30)"
      >
        {abbreviateLabel(value)}
      </text>
    </g>
  );
}

/**
 * Custom tooltip component for chart rollover data
 */
function CustomTooltip({ active, payload, label }) {
  if (!active || !payload || !payload.length) return null;

  return (
    <div className="bg-white shadow-md p-2 rounded text-sm space-y-1">
      <p className="label text-gray-700 mb-1 font-bold text-md">{`Asset: ${label}`}</p>
      {payload.map((item, index) => {
        return (
          <>
            {index === 0 && <p className="label text-gray-700 mb-1 font-bold text-md">{`Month: ${formatMonth(item.payload.month)}`}</p>}
            <p key={index} className="font-semibold" style={{ color: item.color }}>
              {item.name}:{' '}
              <span className="font-bold text-md">
                {typeof item.value === 'number'
                  ? +(Math.round(item.value + "e+2") + "e-2")
                  : item.value}
              </span>
            </p>
          </>
        )
      })}
    </div>
  );
}

/**
 * Chart component to display rollover data
 */
function RolloverChart({ data, bars, xAxisKey1, xAxisKey2, xAxisFormatter, chartTitle }) {
  const dynamicWidth = Math.max(data.length * 100, 1000);

  return (
    <>
      <h2 className="text-xl font-bold mr-auto my-6">{chartTitle}</h2>
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <div style={{ minWidth: `${dynamicWidth}px`, height: '500px' }}>
          <ResponsiveContainer>
            <ComposedChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={xAxisKey1}
                tick={(props) => <CustomTick {...props} />}
                interval={0}
                height={100}
                tickFormatter={xAxisFormatter}
              />

              <XAxis
                dataKey={xAxisKey2}
                xAxisId={xAxisKey2}
                interval={0}
                height={40}
                tick={{ fontSize: 12 }}
                allowDuplicatedCategory={false}
                type="category"
                tickFormatter={(value) => formatMonth(value)}
                axisLine={false}
                tickLine={false}
              />
              <YAxis />

              <Tooltip content={<CustomTooltip />} />

              <Legend wrapperStyle={{ marginTop: '20px' }} />
              {bars.map((bar) => (
                <Bar
                  key={bar.dataKey}
                  dataKey={bar.dataKey}
                  stackId={bar.stackId}
                  fill={bar.fill}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}

/**
 * Filter section for the rollover component
 */
const RolloverFilterSection = memo(({
  title,
  isOpen,
  items,
  selectedItems = [],
  handleChangeSelectedItems,
  searchTerm,
  setSearchTerm,
  openAccordionItem,
  handleAccordionToggle,
  isLoading,
  isMultiSelect = false,
  isDisabled = false,
}) => {
  const isActive = openAccordionItem === title;
  const borderColor = isActive && selectedItems.length === 0 ? 'border-blue-400' : 'border-grey-200';

  const handleInputChange = (e) => setSearchTerm(e.target.value);

  const handleItemChange = (item) => {
    if (isMultiSelect) {
      const updatedSelection = selectedItems.includes(item)
        ? selectedItems.filter((selected) => selected !== item)
        : [...selectedItems, item];

      handleChangeSelectedItems(updatedSelection);
    } else {
      handleChangeSelectedItems(item === selectedItems[0] ? [] : [item]);
    }
  };

  const handleClearSelection = () => {
    handleChangeSelectedItems([]);
    setSearchTerm('');
  };

  const filteredItems = items?.filter((item) =>
    item && item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Accordion.Item value={title}>
      <Accordion.Trigger
        className={`flex justify-between items-center w-full ${borderColor} rounded px-2 border ${isLoading ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'}`}
      >
        <div className="flex items-center gap-2">
          {selectedItems.length > 0 ? (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleClearSelection();
                }}
              >
                <XMarkIcon className="h-5 w-5 text-gray-500" />
              </button>
              <p className="text-gray-700 py-2 text-left">{isMultiSelect ? `${selectedItems.length} selected` : selectedItems[0]}</p>
            </>
          ) : (
            isLoading ? (
              <div className="text-gray-500 text-center py-2">Loading...</div>
            ) : (
              <>
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAccordionToggle(title);
                  }}
                />
                <input
                  type="text"
                  placeholder={`Search ${title}`}
                  className="outline-none border-none focus:ring-0"
                  value={searchTerm}
                  onChange={handleInputChange}
                  onMouseDown={(e) => {
                    if (document.activeElement !== e.target || !isActive) {
                      handleAccordionToggle(title);
                    }
                  }}
                  disabled={isDisabled}
                />
              </>
            )
          )}
        </div>
        {!isDisabled && (
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              if (isOpen) {
                handleAccordionToggle('');
              } else {
                handleAccordionToggle(title);
              }
            }}
          />
        )}
      </Accordion.Trigger>
      {isOpen && (
        <Accordion.Content className={`mt-2 ${borderColor} rounded p-2 border`}>
          {isLoading ? (
            <div className="text-gray-500 text-center py-4">Loading...</div>
          ) : (
            filteredItems?.map((item) => (
              <label key={item} className="flex justify-between items-center border-b border-grey-200 text-sm py-2 px-3 first:border-t">
                <span className="ml-2 text-gray-700">{item}</span>
                <input
                  type={isMultiSelect ? 'checkbox' : 'radio'}
                  checked={selectedItems.includes(item)}
                  onChange={() => handleItemChange(item)}
                  className={isMultiSelect ? 'form-checkbox h-4 w-4 text-blue-500 focus:ring-0' : 'form-radio h-4 w-4 text-blue-500 focus:ring-0'}
                />
              </label>
            ))
          )}
        </Accordion.Content>
      )}
    </Accordion.Item>
  );
});

/**
 * Main component for rendering rollover charts and filters
 */
export default function RolloverComponent() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedAssets, setSelectedAssets] = useState([]);

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [searchTerms, setSearchTerms] = useState({ products: '', assets: '' });
  const [openAccordionItem, setOpenAccordionItem] = useState('');

  const { id: baseModelId } = useParams();

  const { t } = useLocalization();

  /**
   * Receiving plan chart methods:
   * Imported Volume, Transfers, FOB Producer, CIF Contracted Producer,
   * CIF Uncontracted Producer, KA Contracted, KA Uncontracted,
   * Broker Contracted, Broker Uncontracted
   */
  const receivingPlanChartBars = useMemo(() => [
    { dataKey: t('rolloverComponent.receivingPlan.bars.importedVolume'), stackId: 'a', fill: '#3b82f6' },
    { dataKey: t('rolloverComponent.receivingPlan.bars.transfers'), stackId: 'a', fill: '#e7ca38' },
    { dataKey: t('rolloverComponent.receivingPlan.bars.fobProducer'), stackId: 'a', fill: '#38bdf8' },
    { dataKey: t('rolloverComponent.receivingPlan.bars.cifContractedProducer'), stackId: 'a', fill: '#1e40af' },
    { dataKey: t('rolloverComponent.receivingPlan.bars.cifUncontractedProducer'), stackId: 'a', fill: '#6366f1' },
    { dataKey: t('rolloverComponent.receivingPlan.bars.kaContracted'), stackId: 'a', fill: '#4f46e5' },
    { dataKey: t('rolloverComponent.receivingPlan.bars.kaUncontracted'), stackId: 'a', fill: '#7c3aed' },
    { dataKey: t('rolloverComponent.receivingPlan.bars.brokerContracted'), stackId: 'a', fill: '#a78bfa' },
    { dataKey: t('rolloverComponent.receivingPlan.bars.brokerUncontracted'), stackId: 'a', fill: '#a9adb6' },
  ], [t]);

  const transformReceivingPlanChartData = useCallback((data, selectedAssets) => {
    return data
    .filter((item) => {
      const normalizedDate = normalizeDate(item.month);
      const date = normalizedDate ? new Date(normalizedDate) : null;
      return (
        selectedAssets.includes(item.asset) && date
      );
    })
      .map((item) => ({
        month: item.month,
        asset: item.asset,
        [t('rolloverComponent.receivingPlan.bars.importedVolume')]: item.imported_vol,
        [t('rolloverComponent.receivingPlan.bars.transfers')]: item.fob_transfer,
        [t('rolloverComponent.receivingPlan.bars.fobProducer')]: item.fob_producer,
        [t('rolloverComponent.receivingPlan.bars.cifContractedProducer')]: item.cif_contracted_vol,
        [t('rolloverComponent.receivingPlan.bars.cifUncontractedProducer')]: item.cif_uncontracted_vol,
        [t('rolloverComponent.receivingPlan.bars.kaContracted')]: item.cif_ka_contracted_vol,
        [t('rolloverComponent.receivingPlan.bars.kaUncontracted')]: item.cif_ka_uncontracted_vol,
        [t('rolloverComponent.receivingPlan.bars.brokerContracted')]: item.cif_broker_contracted_vol,
        [t('rolloverComponent.receivingPlan.bars.brokerUncontracted')]: item.cif_broker_uncontracted_vol,
      }));
  }, [t]);

  /**
   * Rollover consolidated chart methods:
   * Producer, KA, Broker
   */
  const rolloverConsolidatedChartBars = useMemo(() => [
    { dataKey: t('rolloverComponent.rolloverConsolidated.bars.producer'), stackId: 'a', fill: '#7161CA' },
    { dataKey: t('rolloverComponent.rolloverConsolidated.bars.ka'), stackId: 'a', fill: '#4C39B4' },
    { dataKey: t('rolloverComponent.rolloverConsolidated.bars.broker'), stackId: 'a', fill: '#A239B4' },
  ], [t]);

  const transformRolloverConsolidatedChartData = useCallback((originationCifAssets, selectedProduct, selectedAssets) => {
    if (!originationCifAssets || !selectedProduct) return [];

    const aggregatedData = originationCifAssets.reduce((acc, item) => {
      if (item.product === selectedProduct.product && selectedAssets.includes(item.asset)) {
        const key = `${item.month} - ${item.asset}`;

        if (!acc[key]) {
          acc[key] = {
            month: item.month,
            asset: item.asset,
            [t('rolloverComponent.rolloverConsolidated.bars.producer')]: 0,
            [t('rolloverComponent.rolloverConsolidated.bars.ka')]: 0,
            [t('rolloverComponent.rolloverConsolidated.bars.broker')]: 0,
          };
        }

        acc[key][t('rolloverComponent.rolloverConsolidated.bars.producer')] += item.rollover_cif || 0;
        acc[key][t('rolloverComponent.rolloverConsolidated.bars.ka')] += item.rollover_ka || 0;
        acc[key][t('rolloverComponent.rolloverConsolidated.bars.broker')] += item.rollover_broker || 0;
      }
      return acc;
    }, {});

    return Object.values(aggregatedData);
  }, [t]);


  const { data: productsData, isValidating: isLoadingProducts } = useSWR(`/rollover/products/${baseModelId}`, { suspense: false });

  const { data: assetsData, isValidating: isLoadingAssets } = useSWR(
    selectedProduct ? `/rollover/assets/${baseModelId}/${selectedProduct.product}` : null,
    { suspense: false }
  );

  const { data: chartData, isValidating: isLoadingChartData } = useSWR(
    selectedProduct && selectedAssets.length > 0 && filtersApplied
      ? `/rollover/output/${baseModelId}/${selectedProduct.product}?assets=${selectedAssets.join(',')}`
      : null,
    { suspense: false }
  );

  const unifiedData = useMemo(() => {
    if (!chartData) return [];
    return normalizeChartData(chartData.inboundConsolidated, chartData.originationCifAssets);
  }, [chartData]);

  const receivingPlanChartData = useMemo(() => {
    return transformReceivingPlanChartData(unifiedData, selectedAssets);
  }, [unifiedData, selectedAssets, transformReceivingPlanChartData]);

  const rolloverConsolidatedChartData = useMemo(() => {
    return transformRolloverConsolidatedChartData(unifiedData, selectedProduct, selectedAssets);
  }, [transformRolloverConsolidatedChartData, unifiedData, selectedProduct, selectedAssets]);

  const handleApplyFilters = () => {
    if (!selectedProduct || selectedAssets.length === 0) {
      return;
    }

    setFiltersApplied(true);
    setFilterOpen(false);
  };

  const isLoading = isLoadingChartData && !chartData;

  const shouldDisplayChart = filtersApplied && chartData;

  return (
    <div className="relative">
      <div className="flex justify-between items-center bg-white pl-8 pr-4 py-1.5 rounded-md border border-[#E0E0E0]">
        <h2 className="text-lg font-semibold text-grey-500">{t('rolloverComponent.title')}</h2>
        <button
          onClick={(e) => {
            if (isLoading) return;
            e.stopPropagation();
            setFilterOpen((prev) => !prev);
          }}
          disabled={isLoadingProducts}
          className={`flex items-center py-2 px-4 rounded shadow ${isLoadingProducts ? 'bg-gray-100 hover:bg-gray-100 text-grey-300 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-200 text-grey-600 cursor-pointer'}`}
        >
          <AdjustmentsVerticalIcon strokeWidth="1.5" className="block h-6 w-5" />
          <span className="ml-2">{t('rolloverComponent.filterButton')}</span>
        </button>
      </div>

      {isFilterOpen && (
        <div className="absolute right-4 top-16 bg-white p-6 shadow-xl rounded-md w-80 z-10">
          {/* Filters */}
          <Accordion.Root type="single" collapsible className="space-y-4" value={openAccordionItem}>
            <RolloverFilterSection
              title={t('rolloverComponent.filters.productsTitle')}
              isOpen={openAccordionItem === t('rolloverComponent.filters.productsTitle')}
              items={productsData?.map((product) => product.product) || []}
              selectedItems={selectedProduct ? [selectedProduct.product] : []}
              handleChangeSelectedItems={(products) => {
                setSelectedProduct(productsData?.find((p) => p.product === products[0]) || null);
              }}
              searchTerm={searchTerms.products}
              setSearchTerm={(term) => setSearchTerms({ ...searchTerms, products: term })}
              openAccordionItem={openAccordionItem}
              handleAccordionToggle={setOpenAccordionItem}
              isLoading={isLoadingProducts}
              isMultiSelect={false}
              isDisabled={isLoadingProducts}
            />

            <RolloverFilterSection
              title={t('rolloverComponent.filters.assetsTitle')}
              isOpen={openAccordionItem === t('rolloverComponent.filters.assetsTitle')}
              items={assetsData?.map((asset) => asset.asset) || []}
              selectedItems={selectedAssets}
              handleChangeSelectedItems={setSelectedAssets}
              searchTerm={searchTerms.assets}
              setSearchTerm={(term) => setSearchTerms({ ...searchTerms, assets: term })}
              openAccordionItem={openAccordionItem}
              handleAccordionToggle={setOpenAccordionItem}
              isLoading={isLoadingAssets}
              isMultiSelect={true}
              isDisabled={!selectedProduct || isLoadingAssets}
            />
          </Accordion.Root>

          <div className="flex justify-end gap-4 mt-4">
            <button
              onClick={() => setFilterOpen(false)}
              className="bg-gray-100 text-gray-700 py-1.5 px-4 rounded shadow"
            >
              {t('rolloverComponent.filters.cancel')}
            </button>
            <button
              onClick={handleApplyFilters}
              className={`py-1.5 px-4 rounded shadow ${
                selectedProduct && selectedAssets.length > 0 ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
              disabled={!selectedProduct || selectedAssets.length === 0 || isLoading}
            >
              {t('rolloverComponent.filters.apply')}
            </button>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className='mt-24'> <Loader variant='small' /> </div>
      ) : (
        shouldDisplayChart && (
          <div className='gap-4'>
            <div className="flex flex-col mt-3 justify-between items-center bg-white pl-8 pr-4 py-1.5 rounded-md border border-[#E0E0E0]">
              <RolloverChart
                chartTitle={t('rolloverComponent.receivingPlan.title')}
                data={receivingPlanChartData}
                bars={receivingPlanChartBars}
                xAxisKey1="asset"
                xAxisKey2="month"
                xAxisFormatter={(value) => abbreviateLabel(value)}
              />
            </div>
            <div className="flex flex-col mt-6 bg-white pl-8 pr-4 py-1.5 rounded-md border border-[#E0E0E0]">
              <RolloverChart
                chartTitle={t('rolloverComponent.rolloverConsolidated.title')}
                data={rolloverConsolidatedChartData}
                bars={rolloverConsolidatedChartBars}
                xAxisKey1="asset"
                xAxisKey2="month"
                xAxisFormatter={(value) => abbreviateLabel(value)}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
}

