import { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NewOptimization from 'screens/NewOptimization'
import NotFound from 'screens/NotFound'
import Home from 'screens/Home'
import Optimization from 'screens/Optimization'
import InfeasibleRun from 'screens/Optimization/InfeasibleRun'
import ChooseOutputDownload from 'screens/Optimization/ChooseOutputDownload'
import Container from 'components/Container/BaseContainer'
import AssetsConfigurations from 'screens/AssetsConfigurations'
import Configuration from 'screens/Configuration'
import Scenarios from 'screens/Scenarios'
import Header from 'components/Header'
import DesignSystem from 'screens/DesignSystem' // dev only
import Loader from 'components/Feedback/Loader/Loader'
import { UserInputsProvider } from 'providers/UserInputsProvider'
import Inconsistencies from 'screens/Optimization/Inconsistencies'
import GlobalProvider from 'providers/GlobalProvider'
import { VisJs } from 'networkGraph/graphs/VisJs/index'
import { AuthenticationState } from 'react-aad-msal'
import LogErrorPage from 'screens/Home/LogError'
import Settings from '../NewExecution/index'

/**
 * @param {AuthenticationState} authenticationState
 * @param {IAccountInfo} accountInfo
 * @returns Main entry point once logged in (contains router)
 */
export default function Dashboard({
  logout,
  authenticationState,
  setUser,
  accountInfo
}) {
  useEffect(() => {
    if (authenticationState === AuthenticationState.Authenticated) {
      try {
        const token = accountInfo?.jwtIdToken
        const user = accountInfo?.account?.userName
        if (user) {
          setUser(user, token)
        } else {
          throw new Error('Username not found')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }, [
    accountInfo?.account?.userName,
    accountInfo?.jwtIdToken,
    authenticationState,
    setUser
  ])

  return (
    <Suspense fallback={<Loader />}>
      {/* Main content */}
      <BrowserRouter>
        <GlobalProvider>
          <Routes>
            <Route
              index
              exact
              path='/'
              element={
                <Container>
                  <Header logout={logout} />
                  <Home />
                </Container>
              }
            />
            <Route
              index
              exact
              path='/assets'
              element={
                <Container>
                  <Header logout={logout} />
                  <AssetsConfigurations />
                </Container>
              }
            />
            <Route
              index
              exact
              path={`/assets/:asset`}
              element={
                <>
                  <Header logout={logout} />
                  <Suspense fallback={<Loader />}>
                    <Configuration />
                  </Suspense>
                </>
              }
            />
            <Route
              index
              exact
              path={`/assets/:asset/:id`}
              element={
                <>
                  <Header logout={logout} />
                  <Suspense fallback={<Loader />}>
                    <Configuration />
                  </Suspense>
                </>
              }
            />
            <Route
              index
              exact
              path='/execution/error/:name/:id'
              element={
                <Container>
                  <Header logout={logout} />
                  <Suspense fallback={<Loader />}>
                    <LogErrorPage />
                  </Suspense>
                </Container>
              }
            />
            <Route
              index
              exact
              path='/execution/:id'
              element={
                <Container>
                  <Header logout={logout} />
                  <Suspense fallback={<Loader />}>
                    <Optimization />
                  </Suspense>
                </Container>
              }
            />
            <Route
              index
              exact
              path='/execution/:id/download/choose-output'
              element={
                <Container>
                  <Header logout={logout} />
                  <Suspense fallback={<Loader />}>
                    <ChooseOutputDownload />
                  </Suspense>
                </Container>
              }
            />
            <Route
              exact
              path={`/execution/:id/scenario`}
              element={
                <Container>
                  <Header logout={logout} />
                  <Scenarios />
                </Container>
              }
            />
            <Route
              exact
              path='/new-optimization'
              element={
                <Container>
                  <UserInputsProvider>
                    <Header logout={logout} />
                    <Suspense fallback={<Loader />}>
                      <NewOptimization />
                    </Suspense>
                  </UserInputsProvider>
                </Container>
              }
            />
            <Route
              exact
              path='/new-execution'
              element={
                <Container>
                  <UserInputsProvider>
                    <Header logout={logout} />
                    <Suspense fallback={<Loader />}>
                      <Settings />
                    </Suspense>
                  </UserInputsProvider>
                </Container>
              }
            />
            <Route
              index
              exact
              path='/execution/:id/inconsistencies'
              element={
                <Container>
                  <Header logout={logout} />
                  <Suspense fallback={<Loader />}>
                    <Inconsistencies />
                  </Suspense>
                </Container>
              }
            />
            <Route
              index
              exact
              path='/execution/:id/infeasible'
              element={
                <Container>
                  <Header logout={logout} />
                  <Suspense fallback={<Loader />}>
                    <InfeasibleRun />
                  </Suspense>
                </Container>
              }
            />
            {process.env.NODE_ENV === 'development' && (
              <Route
                exact
                path='/design-system'
                element={
                  <Container>
                    <Header logout={logout} />
                    <Suspense fallback={<Loader />}>
                      <DesignSystem />
                    </Suspense>
                  </Container>
                }
              />
            )}
            <Route
              path='/network-graph'
              element={
                <Container>
                  <VisJs />
                </Container>
              }
            />
            <Route
              path='*'
              element={
                <Container>
                  <Header logout={logout} />
                  <NotFound />
                </Container>
              }
            />
          </Routes>
        </GlobalProvider>
      </BrowserRouter>
    </Suspense>
  )
}
