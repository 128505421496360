import { Suspense } from 'react'
import Loader from 'components/Feedback/Loader/Loader'
import Overview from './Tabs/Overview'
import FinancialOverview from './Tabs/FinancialOverview/FinancialOverview'
import Transportation from './Tabs/Transportation'
import Origination from './Tabs/Origination'
import Storage from './Tabs/Storage'
import Crushing from './Tabs/Crushing'
import InternalMarket from './Tabs/InternalMarket'
import Export from './Tabs/Export'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import TabsNav from './Tabs/TabsNav'
import { Ownership } from './Tabs/Ownership'
import { useLocalization } from 'providers/LocalizationProvider'

function OptimizationMain() {
  const { countryCode, t } = useLocalization()
  const { page } = useOptimizationContext()

  return (
    <div className='flex-1 flex flex-col min-h-screen'>
      <Suspense fallback={<Loader />}>
        <div className='px-8 overflow-x-auto'>
          <TabsNav />
        </div>
        <div className='flex flex-1 h-full overflow-auto pb-44'>
          <div className='w-full'>
            {countryCode === 'BR' && (
              <>
                {page === 0 && <Overview />}
                {page === 1 && <FinancialOverview />}
                {page === 2 && <Origination />}
                {page === 3 && <Storage />}
                {page === 4 && <Transportation />}
                {page === 5 && <Crushing />}
                {page === 6 && <InternalMarket />}
                {page === 7 && <Export />}
              </>
            )}
            {countryCode === 'ARG' && (
              <>
                {page === 0 && <Overview />}
                {page === 1 && <FinancialOverview />}
                {page === 2 && <Origination />}
                {page === 3 && <Ownership />}
                {page === 4 && <Storage />}
                {page === 5 && <Transportation />}
                {page === 6 && <Crushing />}
                {page === 7 && <InternalMarket />}
                {page === 8 && <Export />}
              </>
            )}
          </div>
        </div>
      </Suspense>
    </div>
  )
}

export default OptimizationMain
