import { useMemo, useState } from 'react'
import { ExcelIllustration } from 'components/Icons'
import { getBlob } from 'utils/request'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import Button from 'components/Inputs/Button'
import RadioGroup from 'components/Inputs/RadioGroup'
import { ModalComponent } from 'components/Modal/ModalComponent'
import fileSaver from 'file-saver'
import { useToast } from 'providers/ToastProvider'
import { useLocalization } from 'providers/LocalizationProvider'
import ProgressBar from 'components/Feedback/Loader/ProgressBar'
import { useNavigate } from 'react-router-dom'

const DownloadOutputModal = ({ optimizationId }) => {
  const [loading, setLoading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(undefined)
  const [downloadValue, setDownloadValue] = useState('entire')
  const { showToast } = useToast()
  const { showDownloadModal, setShowDownloadModal, page } =
    useOptimizationContext()
  const { t } = useLocalization()
  const navigate = useNavigate()

  const pageProp = useMemo(() => {
    switch (page) {
      case 0:
        return 'overview'
      case 1:
        return 'financial-overview'
      case 2:
        return 'origination'
      case 3:
        return 'storage'
      case 4:
        return 'transportation'
      case 5:
        return 'crushing'
      case 6:
        return 'internal-market'
      case 7:
        return 'export'
      default:
        return ''
    }
  }, [page])

  const onClickHandler = async (e) => {
    setLoading(true)

    if (downloadValue === 'entire') {
      try {
        const outputResponse = await getBlob(
          `/execution/outputs/${optimizationId}/download`,
          undefined,
          setDownloadProgress
        )
        fileSaver(outputResponse, 'outputs.zip')
        setLoading(false)
        setShowDownloadModal(false)
      } catch (err) {
        showToast({
          message: t('optimizationScreen.downloadOutputModal.downloadError'),
          placement: 'top',
          status: 'error'
        })
        setLoading(false)
      }
    } else if (downloadValue === 'chooseOutput') {
      try {
        navigate(`download/choose-output`, { relative: 'path' })
        setLoading(false)
      } catch (err) {
        showToast({
          message: t('optimizationScreen.downloadOutputModal.downloadError'),
          placement: 'top',
          status: 'error'
        })
        setLoading(false)
      }
    } else {
      try {
        const outputResponse = await getBlob(
          `/execution/outputs/${optimizationId}/download/${pageProp}`,
          undefined,
          setDownloadProgress
        )
        fileSaver(outputResponse, `outputs-${pageProp}.zip`)
        setLoading(false)
        setShowDownloadModal(false)
      } catch (err) {
        showToast({
          message: t('optimizationScreen.downloadOutputModal.downloadError'),
          placement: 'top',
          status: 'error'
        })
        setLoading(false)
      }
    }
  }

  return (
    <ModalComponent
      open={showDownloadModal}
      onOpenChange={setShowDownloadModal}
      allowClosing={!loading}
    >
      <div className='flex flex-col items-center justify-center bg-white rounded-lg border-gray-200 p-6'>
        <ExcelIllustration />
        <div className='flex flex-col space-y-9 items-center justify-center w-full'>
          <div className='flex flex-col space-y-6 items-start justify-center'>
            <div className='flex flex-col space-y-2  justify-center items-center'>
              <p className='text-base font-bold leading-normal text-center text-gray-800'>
                {t('optimizationScreen.downloadOutputModal.downloadModel')}
              </p>
              <p className='text-xs leading-none text-center text-gray-500'>
                {t('optimizationScreen.downloadOutputModal.downloadModelHint')}
              </p>
            </div>
            <div className='flex flex-col space-y-3 items-start justify-start '>
              <RadioGroup
                className='flex flex-col'
                value={downloadValue}
                onChange={setDownloadValue}
                disabled={loading}
                options={[
                  {
                    value: 'entire',
                    label: t(
                      'optimizationScreen.downloadOutputModal.entireModel'
                    )
                  },
                  {
                    value: 'chooseOutput',
                    label: 'Choose Output'
                  },
                  {
                    value: 'current',
                    label: t(
                      'optimizationScreen.downloadOutputModal.onlyCurrent'
                    )
                  }
                ]}
              />
            </div>
          </div>
          {loading ? (
            <ProgressBar
              loaded={downloadProgress?.loaded}
              total={downloadProgress?.total}
              variant='transfer'
            />
          ) : (
            <Button
              size='s'
              variant='filled'
              className='w-full'
              disabled={loading}
              onClick={onClickHandler}
            >
              {t('optimizationScreen.downloadOutputModal.download')}
            </Button>
          )}
        </div>
      </div>
    </ModalComponent>
  )
}

export default DownloadOutputModal
