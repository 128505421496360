import { useNavigate, useParams } from 'react-router-dom'
import { ChevronLeftIcon, DocumentIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { getBlob } from 'utils/request'
import SearchInput from 'components/Inputs/SearchInput'
import FileSaver from 'file-saver'
import ProgressBar from 'components/Feedback/Loader/ProgressBar';

const fileMapper = (file) => {
  return { fileName: file.split('/').at(-1) }
}

const ChooseOutputDownload = () => {
  const navigate = useNavigate()
  const { id: baseModelId } = useParams()
  const { data: files } = useSWR(`/execution/${baseModelId}/files`)

  const [globalFilter, setGlobalFilter] = useState('')
  const [debouncedGlobalFilter, setDebouncedGlobalFilter] = useState('')
  const [currentRowsData, setCurrentRowsData] = useState([])
  const [loading, setLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(undefined);

  const handleInputChange = (event) => {
    setGlobalFilter(event)
  }

  const handleDownloadFile = async (fileName) => {
    if (loading) return;

    setLoading(true);
    try {
      const blob = await getBlob(`/execution/inputs/${baseModelId}/download?fileName=${fileName}&type=output`, undefined, setDownloadProgress)
      FileSaver.saveAs(blob, fileName);
      setLoading(false);
    } catch (err) {
      showToast({
        message: t('optimizationScreen.downloadInputModal.failedToDownload'),
        placement: 'top',
        status: 'error',
      });
      setLoading(false);
    }
  }

  const handleDownloadAll = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const blob = await getBlob(`/execution/inputs/${baseModelId}/download?type=output`, undefined, setDownloadProgress)
      FileSaver.saveAs(blob, `output-${baseModelId}.zip`);
      setLoading(false);
    } catch (err) {
      showToast({
        message: t('optimizationScreen.downloadInputModal.failedToDownload'),
        placement: 'top',
        status: 'error',
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedGlobalFilter(globalFilter)
    }, 500)

    return () => clearTimeout(timeout)
  }, [globalFilter])

  useEffect(() => {
    setCurrentRowsData(files?.data?.map(fileMapper) || [])
  }, [files])

  const filteredRows = currentRowsData.filter((file) =>
    file.fileName.toLowerCase().includes(debouncedGlobalFilter.toLowerCase())
  )

  return (
    <div className='flex flex-col h-full w-full bg-white overflow-y-hidden'>
      <div className='flex flex-col h-full w-full px-48 py-8 gap-11 overflow-y-auto'>
        <button
          onClick={() => navigate(`/execution/${baseModelId}`)}
          className='flex w-20 h-6 text-slate-600 gap-0.5 items-center justify-start hover:text-slate-700 hover:font-bold'
        >
          <ChevronLeftIcon className='h-4 w-4' strokeWidth={2} />
          <div className='capitalize text-sm'>Back</div>
        </button>

        <div className='flex flex-col gap-10'>
          <p className='text-base font-bold'>Download file</p>
          <SearchInput
            fullWidth
            placeHolder={'Search'}
            value={globalFilter ?? ''}
            onChange={handleInputChange}
          />
          <div className='h-1/2 relative'>
            <table className='w-full min-w-max border-separate border-spacing-0 border-2 border-gray-200 rounded-xl text-sm'>
              <thead className='w-full table-header-group border-separate border-inherit '>
                <tr className='table-row align-[inherit] border-inherit'>
                  <th className='text-left font-bold text-gray-500 align-top bg-gray-100 first:rounded-tl-xl last:rounded-tr-xl border-b border-gray-400/50 py-5 px-4'>
                    File
                  </th>
                  <th className='text-left font-bold text-gray-500 align-top bg-gray-100 first:rounded-tl-xl last:rounded-tr-xl border-b border-gray-400/50 py-5 px-4'>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className='w-full max-h-24 overflow-auto'>
                {filteredRows.map((file, index) => (
                  <tr
                    key={index}
                    className='table-row align-[inherit] border-inherit bg-white group [&:nth-child(2n+2)]:bg-white/80'
                  >
                    <td className='text-left text-gray-600 align-top py-5 px-4'>
                      <div className='flex justify-start gap-1 items-center'>
                        <DocumentIcon className='h-4 w-4 text-gray-500/80' strokeWidth={2} />
                        {file.fileName}
                      </div>
                    </td>
                    <td className='text-left text-gray-600 align-top py-5 px-4'>
                      <button
                        onClick={() => handleDownloadFile(file.fileName)}
                        className='flex items-center gap-1 text-blue-500 hover:underline'
                      >
                        <ArrowDownTrayIcon className='h-4 w-4' />
                        Download
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='flex justify-end sticky py-2 px-48 border-t-2 bg-white'>
        {loading ? (
            <ProgressBar
              loaded={downloadProgress?.loaded}
              total={downloadProgress?.total}
              variant="transfer"
            />
        ) : (
          <button
            className='px-12 py-2 font-bold text-base bg-blue-400 text-white rounded-md'
            onClick={handleDownloadAll}
          >
            Download All
          </button>
        )}
      </div>
    </div>
  )
}

export default ChooseOutputDownload
